import * as React from "react"
import PropTypes from "prop-types"

import { Fragment } from "react"
import { Popover, Transition } from "@headlessui/react"

import {
  MenuIcon,
  //PhoneIcon,//
  XIcon,
} from "@heroicons/react/outline"

import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ")
// }

const Header = ({ siteTitle }) => (
  <header className="relative z-50">
    <Popover className="relative w-full mx-auto ">
      {({ open }) => (
        <>
          <div className="max-w-6xl w-5/6 2xl:w-full mx-auto py-5">
            <div className=" flex justify-between items-center py-2">
              <div className="flex justify-start lg:w-0 lg:flex-1">
                <Link to="/">
                  <span className="sr-only">Book Ibiza</span>
                  <StaticImage
                    width={130}
                    src="../../images/logo-black.png"
                    alt="Book Ibiza"
                  />
                </Link>
              </div>

              <Popover.Group>
                <div className="flex flex-col justify-between items-center">
                  <div className="hidden md:flex md:space-x-10">
                    <a
                      href="/villas"
                      className="text-base font-medium text-black hover:text-gray-900 "
                    >
                      Villas
                    </a>
                    <a
                      href="/guide"
                      className="text-base font-medium text-black hover:text-gray-900 "
                    >
                      Guide
                    </a>
                    <a
                      href="/boats"
                      className="text-base font-medium text-black hover:text-gray-900 "
                    >
                      Boats
                    </a>
                    <a
                      href="/cars"
                      className="text-base font-medium text-black hover:text-gray-900 "
                    >
                      Cars
                    </a>
                    <a
                      href="/blog"
                      className="text-base font-medium text-black hover:text-gray-900 "
                    >
                      Blogs
                    </a>
                    {/* <a
                      href="/experiences"
                      className="text-base font-medium text-gray-900 hover:text-gray-700 "
                    >
                      Experiences
                    </a> */}
                  </div>
                </div>
              </Popover.Group>
              <div className="-mr-2 -my-2 md:hidden">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500">
                  <span className="sr-only">Open menu</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              static
              className="absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right md:hidden"
            >
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                <div className="pt-5 pb-6 px-5">
                  <div className="flex items-center justify-between">
                    <div>
                      <StaticImage
                        width={130}
                        src="../../images/logo-black.png"
                        alt="Book Ibiza"
                      />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500">
                        <span className="sr-only">Close menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                </div>
                <div className="py-6 px-5 space-y-6">
                  <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                    <a
                      href="/villas"
                      className="text-base font-medium text-gray-900 hover:text-gray-700"
                    >
                      Villas
                    </a>

                    <a
                      href="/guide"
                      className="text-base font-medium text-gray-900 hover:text-gray-700"
                    >
                      Guide
                    </a>
                    <a
                      href="/boats"
                      className="text-base font-medium text-black hover:text-gray-900"
                    >
                      Boats
                    </a>
                    <a
                      href="/cars"
                      className="text-base font-medium text-black hover:text-gray-900"
                    >
                      Cars
                    </a>
                    <a
                      href="/blog"
                      className="text-base font-medium text-black hover:text-gray-900"
                    >
                      Blogs
                    </a>
                    {/* <a
                      href="/experiences"
                      className="text-base font-medium text-gray-900 hover:text-gray-700"
                    >
                      Experiences
                    </a> */}
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
